/* Form Styles */
.change-password-form .form-group {
    margin-bottom: 5px;
}

.change-password-form label {
    display: block;
    font-size: 0.75rem;
    margin: 10px 0 5px;
    font-weight: bold;
}

.password-input {
    width: 100%;
    border: 1px solid #ccc;
    /* Gray border for default state */
    border-radius: 4px;
    /* Optional: Rounded corners */
    padding: 8px;
    outline: none;
    /* Removes the default outline */
    transition: border-color 0.3s ease, font-size 0.3s ease;
    /* Smooth transition for border color and size */
    font-size: 0.685rem;
    /* Default font size */
    font-weight: 500;
    font-family: "Arial", sans-serif;
    /* Ensure consistent font rendering */
    box-sizing: border-box;
}

.password-input:focus {
    border: 2px solid #007bff;
    /* Blue border when focused */
}

.password-input:not(:placeholder-shown) {
    font-size: 24px;
    /* Increase font size when input is not empty */
    font-weight: 800;
}

.required {
    color: #3f3d3d;
    font-weight: bold;
}

.modal-header-change-password {
    border-bottom: none;
    padding: 0;
}


/* Buttons */
.btn-white {
    color: var(--blue-dark-4);
    background-color: var(--blue-light-5);
    border: 1px solid #0d0d0e;
    border-radius: 4px;
}

.btn-white:hover {
    color: var(--white);
    background-color: var(--blue-dark-2);
    box-shadow: 0 12px 24px 0 #d5d5df;
}

.btn-blue-gray {
    color: var(--white);
    background-color: #19a3fc;
    border: 1px solid #19a3fc;
    border-radius: 4px;
}

.btn-blue-gray:hover {
    color: var(--white);
    background-color: var(--blue-sec);
    box-shadow: 0 12px 24px 0 rgba(25, 163, 252, 0.25);
}

.btn-blue-gray.disabled,
.btn-blue-gray:disabled {
    opacity: 0.75;
    background-color: #d5d5df !important;
    color: var(--blue-dark-2) !important;
    border: 1px solid #d5d5df !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form Actions */

.form-actions {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 25px;
    padding-bottom: 10px;
}

/* Change Password Messages */

.change-password-messages {
    font-size: 0.75rem;
    font-weight: 600;
    margin: 10px 0 15px 0;
}

.change-password-success {
    color: #06816B;
    cursor: pointer;
}

.change-password-success::before {
    content: "\2713";
    /* Checkmark symbol */
    color: #06816B;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
}

.change-password-error {
    display: flex;
    align-items: center;
    /* Align text and icon vertically */
    color: #D21041;
    /* Red color for error text */
    font-weight: bold;
    /* Make the text bold */
    font-size: 0.8rem;
    /* Adjust text size */
}

.change-password-error::before {
    content: "!";
    /* Unicode for the exclamation mark */
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #D21041;
    border-radius: 50%;
    /* Make it a circle */
    background-color: #fff;
    /* Red background for the circle */
    color: #D21041;
    /* White exclamation mark */
    font-size: 14px;
    /* Adjust size of the exclamation mark */
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    /* Center the exclamation mark within the circle */
    margin-right: 8px;
    /* Add space between icon and text */
    padding-top: 0px;
}

.change-password-warning {
    display: flex;
    align-items: center;
    /* Align text and icon vertically */
    color: #F97316;
    /* Red color for error text */
    font-weight: bold;
    /* Make the text bold */
    font-size: 0.8rem;
    /* Adjust text size */
}

.change-password-warning::before {
    content: "!";
    /* Unicode for the exclamation mark */
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid #F97316;
    border-radius: 50%;
    /* Make it a circle */
    background-color: #fff;
    /* Red background for the circle */
    color: #F97316;;
    /* White exclamation mark */
    font-size: 14px;
    /* Adjust size of the exclamation mark */
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    /* Center the exclamation mark within the circle */
    margin-right: 8px;
    /* Add space between icon and text */
    padding-top: 0px;
}

.alert-error-modal .alert-warning-modal {
    font-size: 14px;
    /* Adjust size of the exclamation mark */
    font-weight: 300;
    color: var(--blue-dark-4);
}