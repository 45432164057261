/* Form Styles */
.edit-user-form .form-group {
    margin-bottom: 5px;
}

.edit-user-form label {
    display: block;
    font-size: 0.75rem;
    margin: 10px 0 5px;
    font-weight: bold;
}

.edit-user-form input,
.edit-user-form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 0.685rem;
    font-weight: 500;
    background-color: #f4f3f9;
    box-sizing: border-box;
}

.edit-user-form input[readonly] {
    background-color: #D5D5DF;
    color: #383947;
    cursor: not-allowed;
}

.edit-user-form select {
    color: #383947;
    cursor: pointer;
}

.required {
    color: #383947;
    font-weight: bold;
}

.modal-header-edit-user {
    border-bottom: none;
    padding: 0;
  }

/* Dropdown Styles */
.edit-user-form-dropdown {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 0.685rem;
    color: #383947;
    box-sizing: border-box;
}

/* Password Reset Section */
.password-reset p {
    font-size: 0.75rem; 
    margin: 5px 0 10px;
    color: #383947; 
    border: 1px solid #d1d5db;
    border-radius: 4px;
    padding: 10px;
    background-color: #D5D5DF;
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-300, 24px)
}

.password-reset-label,
.password-reset-instructions {
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 20px;
}

.password-reset-label {
    color: #383947;
}

.password-reset-instructions {
    color: #057ecf;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
}

.password-reset-invitation {
    padding-bottom: 15px;
}

/* Buttons */
.btn-white {
    color: var(--blue-dark-4);
    background-color: var(--blue-light-5);
    border: 1px solid #0d0d0e;
    border-radius: 4px;
}

.btn-white:hover {
    color: var(--white);
    background-color: var(--blue-dark-2);
    box-shadow: 0 12px 24px 0 #d5d5df;
}

.btn-blue-gray {
    color: var(--white);
    background-color: #19a3fc;
    border: 1px solid #19a3fc;
    border-radius: 4px;
}

.btn-blue-gray:hover {
    color: var(--white);
    background-color: var(--blue-sec);
    box-shadow: 0 12px 24px 0 rgba(25, 163, 252, 0.25);
}

.btn-blue-gray.disabled,
.btn-blue-gray:disabled {
    opacity: 0.75;
    background-color: #d5d5df !important;
    color: var(--blue-dark-2) !important;
    border: 1px solid #d5d5df !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Invitation Sent */
.invitation-sent {
    color: #06816B;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 600;
    margin: 10px 0 30px;
}

.invitation-sent::before {
    content: "\2713"; /* Checkmark symbol */
    color: #06816B;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
}

/* Input Wrapper */
.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.input-wrapper input {
    padding-right: 20px;
}

.excl-mark {
    position: absolute;
    right: 20px;
    top: 50%; 
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #616277;
    border-radius: 50%;
    color: var(--color-text-subtle, #616277);
    font-family: "Font Awesome 6 Pro";
    font-size: var(--font-size-200, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--font-line-height-300, 24px); 
}

/* Form Actions */
.form-actions {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 25px;
    padding-bottom: 10px;
}